<template>
  <section class="home-top10">
    <div class="top10">
      <h2 class="text-center">This Week&rsquo;s Member Favorites</h2>
      <button
        v-if="selectedTop10Wine > 1"
        class="prev"
        @click="goToPrevTop10Wine"
      >
        Prev
      </button>
      <button v-if="showTop10NextArrow" class="next" @click="goToNextTop10Wine">
        Next
      </button>
      <div
        id="top-10-cards"
        ref="top10cards"
        class="cards"
        @scroll="updateTop10WineByScroll"
      >
        <nuxt-link
          v-for="(wine, index) in top10WinesArray"
          :id="`top-10-${index + 1}`"
          :key="index + 1"
          :to="wine.url"
        >
          <div class="number">{{ index + 1 }}</div>
          <div class="img">
            <img
              v-lazy-load :data-src="wine.bottle_image_url"
              alt="wine"
              class="lazyLoad isLoaded"
            />
            <div class="divider">&nbsp;</div>
          </div>
          <div class="winetagline">
            {{ wine.narrative_tag_line }}
            <div class="winename">{{ wine.title }}</div>
          </div>
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Top10WinesSection',
  props: {
    top10WinesArray: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showTop10NextArrow: true,
      selectedTop10Wine: 1,
    }
  },
  methods: {
    goToNextTop10Wine() {
      this.$scrollTo(`#top-10-${this.selectedTop10Wine + 1}`, 500, {
        container: '#top-10-cards',
        offset: 0,
        x: true,
        y: false,
      })
    },
    goToPrevTop10Wine() {
      this.$scrollTo(
        `#top-10-${Math.max(1, this.selectedTop10Wine - 1)}`,
        500,
        {
          container: '#top-10-cards',
          offset: 0,
          x: true,
          y: false,
        }
      )
    },
    updateTop10WineByScroll({
      target: { scrollLeft, scrollWidth, clientWidth },
    }) {
      const firstWidth = this.$refs.top10cards.children[0].scrollWidth
      const nthWidth = this.$refs.top10cards.children[1].scrollWidth
      if (scrollLeft < firstWidth) {
        this.selectedTop10Wine = 1
      } else {
        this.selectedTop10Wine =
          Math.floor((scrollLeft - firstWidth) / nthWidth) + 2
      }
      this.showTop10NextArrow =
        scrollLeft + nthWidth < scrollWidth - clientWidth
    },
  },
}
</script>
